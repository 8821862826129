.zDex {
    z-index: 650 !important;
}

.icoLinks {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 30%);

    position: absolute;
    right: 0.1em;
    top: 0.1em;
    color: white;
    height: 100%;
    grid-gap: 3%;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            font-size: min(7vw, 30px);
            width: 1em;
            height: 1em;
        }
        button {
            color: rgba(255, 255, 255, 0.9);
        }
        button:hover {
            color: rgba(255, 255, 255, 1);
            transform: scale(1.1);
        }
    }
}
.menIco:hover {
    cursor: pointer;
}

#dropdown-menu {

    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper,
    .ddl-opt {
        background-color: rgba(2, 43, 149, 0.98) !important;
        color: white;

        ul:hover {
            li {
                transform: scale(1.08);
            }
        }
    }
}
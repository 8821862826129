.invalid {
    color: red !important;
    margin-bottom: 2%;
}

.frm {
    background-color: white;
    color: black;
    height: 95%;
    /* overflow-y: scroll; */
    overflow: hidden;
    padding: 2%;

    display: grid;
    grid-template: 90% 10% / 100%;

    .controls-container {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;        
        padding: 2%;
        overflow-y: auto;

        .control {
            margin-bottom: 3%;
            .question-div {
                margin-bottom: 1%;
            }
            .control-div {

                .tierDiv {
                    display: flex;
                    div:first-child {
                        flex: none;
                    }
                    div:not(:first-child) {
                        flex-basis: 33%;
                    }
                    div {
                        flex: 1;                        
                    }
                }
            }
            .err {
                font-weight: bold;
                font-size: 14px;
                color: #F44336;
            }
        }
    }
    .btn-container {

    }
}
@media screen and (max-width: 1050px) {
    .frm {
        .btn {
            width: 20vw;
            left: 30%;
        }
        .btn2 {
            width: 20vw;
            left: 51%;
        }
    }
}


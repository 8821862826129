.Mui-Tooltip-popper {
    position: absolute !important;
}

.portfolio-animate {
    transition: -webkit-transform 0.75s ease-in-out;
    transition: all 0.75s ease-in-out;
}

.port {
    background-color: rgba(0,0,0,0.85);
    color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-auto-rows: 34vh;
    grid-template-columns: repeat(auto-fill,  minmax(20vw, 1fr))
}

.appHldr {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}
.appFS {
    z-index: 1550 !important;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.99);
    animation: toFullScreen 0.75s forwards;
    transition: all ease-in-out;
            
    .title {
        display: none;
    }
    .app {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-rows: 9vh 91vh;
        grid-template-columns: 100%;

        animation: fadeIn 3s forwards;
        transition: all ease-in-out;

        .menuBar {
            display: block;
            width: 100%;
            height: 100%;

            div {
                display: flex;
                justify-content: center;
                font-size: min(5vw, 35px);
                padding-top: 0.25em;

                &:hover {
                    cursor: pointer;
                }
            }
            button {
                position: absolute;
                right: 0;
                top: 0;            

                svg {
                    font-size: min(10vw, 40px);
                    transition: all 0.5s ease-in-out;
                }
            }
            button:hover {
                cursor: pointer;
                svg {
                    transform: rotate(90deg);
                }
            }
        }
        iframe {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
.appOption {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    min-height: 150px;
    min-width: 150px;
    height: 13vw;
    width: 13vw;
    z-index: 600;

    margin: 0 auto;
    text-align: center;
    background-color: grey;
    transition: all 0.2s ease-in-out;
    .title {
        width: 100%;
        background-color: rgba(0,0,0,0.85);
        height: 20%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
    .app {
        display: none;
    }
}
.appOption:hover {
    transform: scale(1.1);
    cursor: pointer;
}
@keyframes toFullScreen {
    0% {
        z-index: 1550 !important;
        height: 0%;
        width: 0%;

        .app {
            opacity: 0;
        }
    }
    100% {
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        .app {
            opacity: 1;
        }
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
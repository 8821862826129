.bodyPaneCntct {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 60% 40%;
    position: relative;

    height: 90%;
    width: 80%;
    border-radius: 12px;
    overflow-y: hidden;
    overflow-x: hidden;

    color: white;
    line-height: 1.25em;
    /* font-size: 2em; */
    font-size: min(5vw, 35px);

    box-shadow: 3px 3px 5px black;

    background-color: rgba(2, 149, 107, 0.98);

    &:hover {
      animation: scrollBorder 8s ease-in-out infinite;
  }
}

.game {
    span:nth-child(1) {
        font-size: 2em;
    }
    span:nth-child(2) {
        font-size: 1em;
    }
}
.gameStart:hover {
    cursor: pointer;
    color: red !important;
}

.sendingCtr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sending {
    width: 90px;
    height: 14px;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 1);
    position: relative;
    clip-path: inset(-40px 0 -5px);
    margin: auto;
    transform: scale(4);
  }
  .sending:before {
    content: "";
    position: absolute;
    inset: auto calc(50% - 17px) 0;
    height: 50px;
    --g:no-repeat linear-gradient(#ccc 0 0);
    background: var(--g),var(--g),var(--g),var(--g);
    background-size: 16px 14px;
    animation:
      l7-1 2s infinite linear,
      l7-2 2s infinite linear;
  }
  @keyframes l7-1 {
    0%,
    100%  {background-position: 0 -50px,100% -50px}
    17.5% {background-position: 0 100%,100% -50px,0 -50px,100% -50px}
    35%   {background-position: 0 100%,100% 100% ,0 -50px,100% -50px}
    52.5% {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% -50px}
    70%,
    98%  {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% calc(100% - 16px)}
  }
  @keyframes l7-2 {
    0%,70% {transform:translate(0)}
    100%  {transform:translate(200%)}
  }

  @keyframes scrollBorder {
    0% {
        border-radius: 50px 12px 12px 12px;
    }
    10% {
        border-radius: 12px 50px 12px 12px;
    }
    20% {
        border-radius: 12px 12px 50px 12px;
    }
    30% {
        border-radius: 12px 12px 12px 50px;
    }
    40% {
        border-radius: 50px 12px 12px 12px;
    }
    50% {
        border-radius: 12px 50px 12px 12px;
    }
    60% {
        border-radius: 12px 12px 50px 12px;
    }
    70% {
        border-radius: 12px 12px 12px 50px;
    }
    80% {
        border-radius: 50px 12px 12px 12px;
    }
    90% {
        border-radius: 12px 50px 12px 12px;
    }
    100% {
        border-radius: 12px 12px 50px 12px;
    }
}
.hide {
  display: none !important;
}
.noOverflow {
  overflow: hidden;
}

/* ::-webkit-scrollbar{
  width: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0 10px 10px 0;
}
::-webkit-scrollbar-thumb{
  background-color: rgba(58,141,239);
  border-radius: 0 10px 10px 0;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.5) inset;
} */
.setFocus {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 100 !important;
}

#root {
  .textBG {
    color: white;
    line-height: min(5vw, 40px);
    font-size: min(5vw, 30px);

    background-color: rgba(2, 149, 107, 0.98);
  }
  .dspFlxCntr {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }


  /* When screen is <=1650px but > 760px */
  @media only screen and (max-width: 1650px) and (min-width: 761px) {
    /* ~~~~~~~~~~~ */
    /* ABOUT PAGE */
    /* ~~~~~~~~~~~ */
      .profImg {
        width: 27vw;
        height: 27vw;
      }
    /* ~~~~~~~~~~~ */
    /* ~~~~~~~~~~~ */

  }
  @media screen and (max-width: 999px) {
    #contact {
      height: 100%;
      margin: 0;
      overflow: auto;

      .bodyPaneCntct {
        height: 100%;
        width: 60%;
        grid-template-columns: 100%;
        background-color: rgba(2, 149, 107, 0);

        .cntctForm  {
          padding: 0 !important;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

        }
        .gameContainer {
          display: none;
        }
      }
    }    
    /* #contact { .bodyPaneCntct { .gameContainer { display: none; } } } */
  }  
  @media screen and (max-width: 760px) and (min-width: 605px) {
    .aboutS {
      .profImg {
        width: 50vw !important;
        height: 50vw !important;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    /* ~~~~~~~~~~~ */
    /* ABOUT PAGE  */
    /* ~~~~~~~~~~~ */
    .aboutS {
      grid-template-columns: 100%;
      grid-template-rows: 50% 49%;

      .aboutPane {
        grid-row: 2;
      }

      .profImg {
        grid-row: 1;
        width: 40vw;
        height: 40vw;
      }
  }
/* ~~~~~~~~~~~ */
/* ~~~~~~~~~~~ */
  }
  /* When screen is 760px or less */
	@media screen and (max-width: 760px) {
    /* ~~~~~~~~~~~ */
    /*     NAV     */
    /* ~~~~~~~~~~~ */
      .hideNav {
        width: 0;
        transition: all 0.4s ease-in-out;

        .navMnu {
          .navLink {
            opacity: 0.1;
          }

          .icoLinks {
            opacity: 0.1;
          }
        }
      }
      .showNav {
        width: 50%;
        transition: all 0.5s ease-in-out;

        .navMnu {
          .navLink {
            opacity: 1;
          }

          .icoLinks {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            opacity: 1;
          }
        }
      }
      .menIco {
        background-color: rgba(2, 43, 149, 0.98);
        z-index: 100;
        position: absolute;
        right: 1%;
        width: 3em;
        height: 3em;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1%;
        color: white;

        opacity: 1;
        transition: all 0.5s ease-in-out;
      }      
      #nav {
        /* height: auto; */
        right: 0%;
        position: absolute;
        overflow: hidden;
        z-index: 100;
        height: 100%;

        .navMnu {
          flex-flow: column;
          padding-right: 0;
          padding-left: 0;
          position: relative;
          width: 100%;
          /* gap: min(5vw, 150px); */

          .active {
            width: 100%;
            justify-content: center;
          }
          .navLink {
            padding: 0;
            height: min(20vw, 200px);
            display: block;
            width: 100%;
          }
          
          .icoLinks {
            grid-template-columns: 30% 30% 30%;
            grid-template-rows: 100%;
            height: 8vw;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            position: relative;
          }
        }
      }


    /* ~~~~~~~~~~~ */
    /* ABOUT PAGE  */
    /* ~~~~~~~~~~~ */
      .aboutS {
          grid-template-columns: 100%;
          grid-template-rows: 42vh 57vh;
          margin-top: 0;
          height: 100vh;

          .aboutPane {
            grid-row: 2;

            div {
              height: 95%;
            }
          }

          .profImg {
            grid-row: 1;
            width: 55vw;
            height: 55vw;
            margin-top: 1vw;
          }
      }
    /* ~~~~~~~~~~~ */
    /* ~~~~~~~~~~~ */

    /* ~~~~~~~~~~~ */
    /* RESUME PAGE  */
    /* ~~~~~~~~~~~ */

    #resume {
      height: 99vh;
      margin: 1%;
      width: 98% !important;  
      .main {

        .header {
          grid-template-columns: 40% 34% 26%;


          .nameSpn {
            font-size: min(5vw, 40px);
          }
          .contact {            
            grid-template-columns: 25% 75%;
          }
          #hdSht {
            width: 20vw;
            height: 20vw;
            position: relative;
    
          }
        }
        .experience {
          grid-template-columns: auto;
          display: block;

          .skills {
            grid-gap: 5%;
            grid-template-columns: 100%;
            grid-template-rows: 45vw 45vw;

            .backEnd {
              grid-row: 2;
            }
            .infrastructure {
              display: none;
            }
          }
        }
      }
    }

    /* ~~~~~~~~~~~ */
    /* ~~~~~~~~~~~ */        

    /* ~~~~~~~~~~~ */
    /* PORTFOLIO PAGE  */
    /* ~~~~~~~~~~~ */
    #portfolio {
      height: 100vh;
      margin: 0;

      .port {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
        grid-auto-rows: 30vw;
        grid-gap: 6vw;
      }

      .appOption {
        width: 25vw;
        height: 25vw;
        min-height: 50px;
        min-width: 50px;

        .title {
          height: 50% !important;
          background-color: rgba(28, 28, 28, 0.75) !important;
        }
      }
    }
    /* ~~~~~~~~~~~ */
    /* ~~~~~~~~~~~ */ 
    
    /* ~~~~~~~~~~~ */
    /* CONTACT PAGE  */
    /* ~~~~~~~~~~~ */
    #contact {
      height: 100%;
      margin: 0;
      overflow: auto;

      .bodyPaneCntct {
        height: 100%;
        width: 95%;
        grid-template-columns: 100%;
        background-color: rgba(2, 149, 107, 0);

        .cntctForm  {
          padding: 0 !important;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

        }
        .gameContainer {
          display: none;
        }
      }
    }
    /* ~~~~~~~~~~~ */
    /* ~~~~~~~~~~~ */     
  }
  @media screen and (max-width: 470px) {
    .profDiv {
      .profImg {
        width: 65vw;
        height: 65vw;
      }
    }
    .aboutS {
      grid-template-rows: 38vh 61vh;

      .aboutPane {
        height: 95%;
      }
    }
  }

}
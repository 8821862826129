.main {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20% 80%;
    background-color: white; 
    box-shadow: 5px 0px 5px black;

    font-family: Arial, Helvetica, sans-serif;

    .header {
        color: white;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 51% 20% 29%;
        background-color: #252525;

        .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5%;
            font-size: min(5vw, 40px);

            .nameSpn:hover {
                cursor: default;
                transition: all 0.5s ease-in-out;
                animation: blowUp 1s forwards;
            }
        }
        .contact {
            display: grid;
            grid-gap: 0.25vw;
            grid-template-rows: auto;
            grid-template-columns: 10% 90%;

            div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    text-decoration: none;
                    color: white;
                }
            }
            .icn {
                transition: transform 1s;
                transform-style: preserve-3d;
                -webkit-backface-visibility: hidden; /* Safari */
                backface-visibility: hidden;
            }
            .icn:hover {
                transform: rotateY(360deg);
            }
            .link:hover {
                cursor: pointer;
                color: #3A8DEF;
                transform: scale(1.075);
            }
        }
        .headShot {
            display: block;
            margin: auto;

            #hdSht {
                background-image: url('../../../shared/assets/hdShot.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 11em;
                height: 11em;
                position: relative;
                top: 1%;
                z-index: 600;
            }
            #hdSht:hover {
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                animation: blowUp 1s forwards;
            }
        }

        .name,
        .headShot {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .experience {
        color: black;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: 48% 49%;
        grid-gap: 2%;
        padding: 0px 1% 0px 1%;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;        

        .leftDetails,
        .rightDetails {
            padding-top: 2%;
        }
        .leftDetails {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 100%;

        }
        .rightDetails {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 100%;

            .skills {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 1fr 1fr;
                padding-bottom: 4%;

                .frontEnd {
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: 1fr 1fr;
                }
                .backEnd {
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: 1fr 1fr;
                    padding-left: 1%;
                }
                .infrastructure {
                    display: grid;
                    grid-template-rows: repeat(8, auto);
                    grid-template-columns: 30% 20% 30% 20%;
                    grid-column-end: span 2;
                    padding-top: 2%;

                    span {
                        grid-column-end: span 4;
                    }
                }

                .frontEnd,
                .backEnd,
                .infrastructure {
                    span {
                        display: block;
                        text-decoration: underline;
                    }
                    .skillTitle {
                        display: inline-block;
                        padding-left: 3%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: min(5vw, 18px);
                    }
                    .skillExp {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .star-bl {
                            color: lightgray;
                        }
                        .star {
                            color: #3A8DEF;
                        }
                    }
                }
            }
        }

        .mainTitle {
            font-size: min(5vw, 30px);
            font-weight: bold;
            display: block;
            padding-bottom: 1%;
        }
        .detailsDiv {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: repeat(3, auto);

            div {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .title {
                font-weight: bold;
                font-size: min(4vw, 25px);
            }
            .date {
                font-size: min(4vw, 20px);
            }
            .title,
            .date {
                color: #3A8DEF;
                padding-top: 1%;
            }
            .location {
                font-weight: bold;
                font-size: min(5vw, 18px);
                display: inline-block;
                padding-left: 1%;
                padding-bottom: 1%;                
            }
            .bullets {
                margin-top: 0;
                margin-bottom: 0;

                li {
                    padding-bottom: 1%;
                    word-spacing: 1px;
                    font-size: min(5vw, 16px);
                }
            }            
        }
    }            
}


@keyframes blowUp {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
.wth-parent {
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: black !important;

    .wth-child {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        width: min(95vw, 1500px);
        height: min(95vh, 750px);
        border-radius: 10px;
        padding: 1%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        overflow-y: auto;

        background-color: white;

        .weather-location {
            /* margin-bottom: 2%; */
            .hdr {
                display: grid;
                grid-template-columns: 78% 18%;
                grid-template-rows: auto auto;
                grid-gap: 4%;

                .ste-sel {
                    align-self: end;
                }

                .cty-sel {

                    .cty-chps {
                        margin-bottom: 2%;
                    }

                    .cty-sel-in {

                        .disabled {
                            opacity: 50%;
                            cursor: not-allowed;
                            pointer-events: none;
                        }
                    }
                }
                .clear-btn {
                    grid-column: span 2;
                    Button {
                        float: right;
                    }
                }
            }

            .MuiFormControl-root>.MuiInputBase-root>.MuiChip-root {
                display: none !important;
            }
        }

        .weather-rpt {
            margin-top: 3%;
            .weather-title {
                display: flex;
                flex-direction: column;

                div {
                    max-height: 35px;
                }
                .wt-1 {
                    padding-bottom: 1vw;
                    span {
                        font-size: min(5vw, 24px);
                        font-weight: bold;
                        background-color: rgb(235,235,235);
                        border-radius: 30px;
                        padding: 10px;
                    }
                }
                .wt-2 {
                    padding-left: 1%;
                    span {
                        font-size: min(5vw, 19px);
                        padding-right: 1%;
                    }
                }
            }

            .wth-fcast-7 {
                grid-template-columns: repeat(auto-fit, minmax(calc(94% / 7), 1fr));
            }

            .wth-fcast-5 {
                grid-template-columns: repeat(auto-fit, minmax(calc(70% / 5), 11vw));
            }

            .wth-fcast-3 {
                grid-template-columns: repeat(auto-fit, minmax(calc(40% / 3), 11vw));
            }

            .weather-forecast {
                display: grid;
                grid-template-rows: 100%;
                position: relative;
                grid-gap: 1%;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1%;
                justify-content: center;

                .weather-day {
                    border-radius: 2%;
                    background-color: rgba(0, 0, 0, 0.85);
                    height: 12vw;
                    max-width: 15vw;
                    width: 80%;
                    display: flex;
                    flex-flow: column;
                    overflow-y: auto;
                    padding: 5%;
                    margin-left: auto;
                    margin-right: auto;

                    background-color: rgba(94, 177, 242, 0.5);
                    box-shadow: 1px 1px 0px black;

                    &:hover {
                        box-shadow: 2px 2px 4px black;
                    }

                    h3 {
                        display: flex;
                        justify-content: center;
                    }

                    .wth-hdr {
                        height: 62%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        background-size: 70% 100%;
                        background-position: center center;
                        background-repeat: no-repeat;

                        div {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }

                        .wth-titl {
                            font-size: min(5vw, 22px);
                            text-align: center;
                            font-weight: bold;
                            width: 100%;
                            height: 40%;
                            background-color: rgba(94, 177, 242, 0.5);
                            box-shadow: 1px 1px 4px black;

                            .dteTtle {
                                font-size: 1vw !important;
                                font-weight: normal !important;
                            }
                        }
                    }

                    .weather-pck {
                        display: grid;
                        height: 100%;
                        grid-gap: 4%;
                        grid-template-columns: 48% 48%;
                        font-size: min(16px, 20px);

                        .tmp {
                            display: grid;
                            grid-template-columns: 100%;
                            justify-content: center;
                            align-items: center;
                            grid-template-rows: auto;
                        }

                        .dtls,
                        .tmp {

                            div {
                                display: flex;
                                flex-flow: row;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .tmpR {
                            grid-template-rows: auto auto !important;
                        }

                        .dtls {}

                        .dtls2 {
                            grid-column: span 2;
                        }

                        .fcst {
                            grid-column: span 2;
                        }

                        .dtls,
                        .tmp {
                            svg {
                                margin-right: 10%;
                            }
                        }

                        .dtls2 {

                            svg {
                                margin-right: 2%;
                            }
                        }

                        .dtls,
                        .dtls2,
                        .fcst {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .weather-rpt {
        margin-bottom: 10vw !important;
        .weather-forecast {
            .weather-day {
                height: 25vw !important;
                max-width: 25vw !important;
            }
        }
    }
    .wth-fcast-3,
    .wth-fcast-5,
    .wth-fcast-7 {
        grid-template-columns: 50% 50% !important;
        grid-template-rows: auto !important;
        grid-gap: 2% !important;
    }
}
@media screen and (max-width: 600px) {
    .weather-title {

        .wt-1 {
            padding-bottom: 5vw !important;
        }
    }
    .weather-rpt {
        margin-top: 10vw !important;

        .weather-forecast {            
            overflow-x: auto;
            grid-gap: 10vw !important;
            height: 60vw;
            margin-left: 5%;
            justify-content: flex-start !important;
            .weather-day {
                height: 50vw !important;
                max-width: 60vw !important;
                width: 44vw !important;
            }
        }
    }
    .wth-fcast-3 {
        grid-template-columns: repeat(3, 44vw) !important;
    }
    .wth-fcast-5 {
        grid-template-columns: repeat(5, 44vw) !important;
    }
    .wth-fcast-7 {
        grid-template-columns: repeat(7, 44vw) !important;
    }
    .wth-fcast-3,
    .wth-fcast-5,
    .wth-fcast-7 {
        grid-template-rows: 100% !important;
        grid-gap: 2% !important;
    }
}